import { CSSProperties, ImgHTMLAttributes } from 'react'

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  isResponsive: boolean
  wrapperStyles?: CSSProperties
}

export default function NativeImage({
  alt,
  src,
  srcSet,
  width,
  height,
  loading,
  style,
  sizes,
  isResponsive,
  wrapperStyles,
}: ImageProps) {
  const responsiveStyles = {
    width: '100%',
    height: '100%',
  }
  const imageStyles = isResponsive ? { ...responsiveStyles, ...style } : style

  return (
    <picture
      style={{
        objectFit: 'contain',
        ...wrapperStyles,
      }}
    >
      <img
        alt={alt}
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        loading={loading}
        style={imageStyles}
        sizes={sizes}
      />
    </picture>
  )
}
